import React from 'react';
import useTranslation from 'next-translate/useTranslation';

import { CUSTOMERS_AMOUNT } from '../../Stats/data';

import classes from './Brands.module.scss';

import ikea from '@/images/home/brands/ikea.svg';
import ing from '@/images/home/brands/ing.svg';
import zara from '@/images/home/brands/zara.svg';
import dbSchenker from '@/images/home/brands/dbschenker.svg';
import paypal from '@/images/home/brands/paypal.svg';
import mediaMarkt from '@/images/home/brands/mediamarkt.svg';

const imagesMap = {
  ikea,
  ing,
  zara,
  dbSchenker,
  paypal,
  mediaMarkt,
};

export const Brands: React.FunctionComponent<React.PropsWithChildren> = () => {
  const { t } = useTranslation('home');

  return (
    <div className={classes.row}>
      {Object.entries(imagesMap).map(([name, image], index) => (
        <img
          className={classes.logo}
          key={index}
          src={image.src}
          alt={`${name} logo`}
          loading="lazy"
        />
      ))}
      <div
        className={classes.clientsCountText}
        dangerouslySetInnerHTML={{
          __html: t(
            '<span class="%{highlightedNumberClass}">+%{amount, number}</span> customers',
            {
              highlightedNumberClass: classes.highlightedNumber,
              amount: CUSTOMERS_AMOUNT,
            },
          ),
        }}
      />
    </div>
  );
};
