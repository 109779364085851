export const COMPANY_FOUNDED_YEAR = 2007;
export const YEARS_OF_COMPANY_EXPERIENCE =
  new Date().getFullYear() - COMPANY_FOUNDED_YEAR;
export const CUSTOMERS_AMOUNT = 9500;
export const SMS_SEND = 1.4;
export const EMPLOYEES_AMOUNT = 75;
export const SATISFIED_USERS_RATE = 97;
export const NPS_SCORE = 81;

export const statistics = {
  companyExperienceYears: YEARS_OF_COMPANY_EXPERIENCE,
  smsSend: SMS_SEND,
  customersAmount: CUSTOMERS_AMOUNT,
  employeesAmount: EMPLOYEES_AMOUNT,
  satisfiedUsersRate: SATISFIED_USERS_RATE,
  npsScore: NPS_SCORE,
};
