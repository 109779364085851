import { ImageProps } from 'next/image';
import clsx from 'clsx';
import Image from 'next/image';

import classes from './MessageExample.module.scss';

interface Props {
  imgSrc: ImageProps['src'];
  title: string;
  message: string;
  className?: string;
}

export const MessageExample: React.FunctionComponent<Props> = ({
  imgSrc,
  message,
  title,
  className,
}) => {
  return (
    <div className={clsx(classes.container, className)}>
      <div className={classes.logoContainer}>
        <Image priority src={imgSrc} alt="" width={43} height={43} />
      </div>
      <div>
        <div className={classes.title}>{title}</div>
        <div className={classes.message}>{message}</div>
      </div>
    </div>
  );
};
